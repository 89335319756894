<template>
  <v-app>
    <v-main>
      <v-card flat class="fill-height box">
        <v-card-text class="row content">
          <v-row align-content="center" justify="center">
            <v-col cols="12" md="8" lg="6">

              <div class="text-center">
                <img :src="require('./assets/lfa-logo.png')" width="400" />
              </div>

              <div class="mb-3">Enter your order number in the field below to check the status:</div>

              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="orderNum" outlined placeholder="Order number" v-mask="['XXX-#####','XXX-######','XXX-#######']"
                    @keypress.enter="getStatus" hide-details></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-btn depressed block large @click="getStatus" :loading="loading"
                    class="white--text" color="grey darken-4" style="text-transform: none;">Get status</v-btn>
                </v-col>
              </v-row>
              
              <div class="results" v-show="showResults">
                Status for order <span class="orderNum">{{ searchedOrder }}</span> is: <v-chip label prominent class="status">{{ status }}</v-chip>
              </div>

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import { API } from '@/inc/api';
import { mask } from 'vue-the-mask';

export default {
  name: 'App',
  data: () => ({
    orderNum      : '',
    searchedOrder : '',
    status        : '',

    loading       : false,
  }),
  computed: {
    showResults() {
      return this.searchedOrder != null 
              && this.searchedOrder.length > 0
              && this.status != null
              && this.status.length > 0;
    }
  },
  created() {
    document.title = 'LFA Order Status'
  },
  methods: {
    async getStatus() {
      try {
        this.searchedOrder = '';
        this.status        = '';

        let api = new API();

        this.loading = true;
        let res = await api.get(`/order/status/${this.orderNum}`);
        this.loading = false;

        if( res.data.status !== true ) {
          return;
        }

        this.searchedOrder = this.orderNum;
        this.status = res.data.data;
        this.orderNum = '';
      }
      catch(error) {
        this.loading = false;
        console.log(error);
      }
    }
  },
  directives: {
    mask
  }
};
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
  flex-flow: column;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

.fit {
  background-color: #ececec;
}

.results {
  margin-top: 20px;
  font-size: 18px;
}
.results .orderNum {
  font-weight: bold;
}
</style>